import React, { useState, useEffect } from "react";
import Layout from "@components/layouts/Layout";
import Seo from "@components/common/Seo";
import SmallBanner from "@components/common/SmallBanner";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import Products from "@components/sections/Products";
import dayjs from "dayjs";
import axios from "axios";
import useSWR from "swr";
import ProductsNew from "@components/sections/products2/Products";

const endpoints = {
    products: "/.netlify/functions/products",
};

const Tienda = () => {
    const {
        data: result,
        isLoading,
        isValidating,
    } = useSWR(endpoints.products, axios.get, {
        dedupingInterval: 30 * 1000,
    });
    const [productsState, setProductsState] = useState([]);

    useEffect(() => {
        if (result?.data?.products) {
            let products = result?.data?.products.filter(
                x => x.enabled && !x.hidden && x.category?.includes("ispc"),
            );

            products = products.map(p => {
                const { created_at, updated_at, ...rest } = p;
                return {
                    ...rest,
                    created_at: dayjs(created_at).toDate(),
                    updated_at: dayjs(updated_at).toDate(),
                };
            });

            products = products.sort((a, b) => b.stock - a.stock || b.created_at - a.created_at);

            setProductsState(products);
        }
    }, [result]);

    return (
        <>
            <Layout>
                <Seo
                    title="Nuestra tienda: Cursos ISPC"
                    description="Asegúrate una escuela tranquila"
                />
                <SmallBanner
                    title="Cursos ISPC"
                    alt="Cursos ISPC"
                    subtitle="Asegúrate una escuela tranquila"
                    filename="bgs/bgISPC"
                />

                <div className="container lg:px-0 py-20">
                    <Title>Cursos ISPC</Title>
                    <Text className="text-justify">
                        Es básico entrar en la escuela de policía con unos conocimientos mínimos
                        para lograr un éxito seguro. ¡Te ayudamos!
                    </Text>
                    <div className="mt-10 space-y-10">
                        {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                            <ProductsNew
                                items={productsState}
                                loading={isLoading || isValidating}
                            />
                        ) : (
                            <Products items={productsState} />
                        )}

                        <div className="mt-10 text-right text-xs italic font-montserrat text-gray-500">
                            * I.V.A. incluido en todos los precios
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default Tienda;
